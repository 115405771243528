import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import _ from "lodash"
import parse from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';

import SEO from "../seo"
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Error from "../../pages/404"
import DetailsHead from './NewsDetailsHead'
import DetailsBody from './NewsDetailsBody'
import Breadcrumb from "../AreaGuideDetails/Breadcrumbs/NewsDetailsBreadcrumb"
import MoreNews from "./MoreNews";
import FormBlock from '../Home/FormBlock/FormBlock';
import logoBlack from "../../images/logo-dark.svg";

const SINGLE_NEWS = gql`
query GETNEWS($URL: String!){
    allNews(where:{URL: $URL}){
        id
        Meta_Title
        Meta_Description
        Title
        Banner_Image {
            url
            alternativeText
        }
        Video_URL
        Date
        Content
        Authors {
          Name
          Designation
          Phone
          Image {
            url
            alternativeText
          }
        }
        Add_More_Details {
          ...on ComponentModulesContent {
            Add_Content
          }
          ...on ComponentModulesShowHelpModule {
            Select_Module {
              Name
              Content
              CTA_1_Label
              CTA_2_Label
              CTA_1_Link {
                id
              }
              CTA_2_Link {
                id
              }
              Select_Form {
                Select
              }
              Show_Chart
            }
          }
        }
    }
    
	globalModule {
        News_Landing_Page {
          Content
          Name
          Select_Form {
            Select
          }
          CTA_1_Label
          CTA_2_Label
          CTA_1_Link {
            id
          }
          CTA_2_Link {
            id
          }
        }
    }
}
`;


function NewsDetails(props) {

	const { loading, error, data } = useQuery(SINGLE_NEWS, {
        variables: { URL: props.slug }
      });

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    
    if (loading) return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img className="logo-white loader-logo" src={logoBlack} alt="logo"/>
            </div>
            <div class="loader-section section-left"></div>
            <div class="loader-section section-right"></div>
          </div>
        </section>
    )

    return (
        <React.Fragment>
        {data.allNews.length !== 0 ?
        <div className="wrapper news-full-details">
          <Header/>
          {data.allNews?.map((item, index) => {  
                return (
                    <>
                        <SEO title={item.Meta_Title ? item.Meta_Title : item.Title} description={item.Meta_Description ? item.Meta_Description : item.Title} image={item.Banner_Image ? item.Banner_Image.url : ''}/>
                        <Breadcrumb Pagename={item.Title} />
                        <DetailsHead {...item} People={item.Authors.length > 0 ? item.Authors[0] : ''}/>
                        <DetailsBody {...item}/>
                        <MoreNews slug={props.slug} />
                        <FormBlock {...data.globalModule?.News_Landing_Page}/>
                    </>               
                )
            })}
          <Footer/>
        </div>
        :
            <Error />
        }
        </React.Fragment>
    )
}

export default NewsDetails;
