import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import ScrollAnimation from "react-animate-on-scroll";
import { Row, Col, Container, Button } from "react-bootstrap";
// import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from 'html-react-parser';
import GenerateLink from "../common/site/generate-link"
import NoImage from "../../images/no-image.jpg"

import _ from "lodash"

import "../Home/GetKnow/GetKnow.scss";

import { inViewOptions1, containerVariants, contentItemStagger, contentItem, fadeInFromTop } from '../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const GuidesLanding = (props) => {

  var all_guides = []

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
        guides(publicationState: LIVE) {
            id
            Title
            URL
            Tile_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 350
                    height: 320
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  ) 
                }
              } 
            }
            Category {
              id
              Name
              Sort
              Details_Page_URL {
                Main_Parent {
                  URL
                }
                Secondary_URL
                Sub_Parent {
                  URL
                }
                Sub_Parent_Secondary {
                  URL
                }
                URL
              }
            }
        }


    }
  }
`);

  all_guides = data.glstrapi.guides.filter(item => {
    let main_category = item.Category.filter(cat => _.includes(cat, props.Select_Guide_Category.id));
    return main_category.length > 0
  });

  return (
    <InView  {...inViewOptions1}>
      {({ ref, inView }) => (
        <motion.div
          className="other-areas-block guides-landing-section components--Guides--GuidesLanding"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}

        >
          <Container>
            <motion.div variants={fadeInFromTop}>
            <Row>
              <Col lg={12}>
                <div className="other-areas-list">
                  <Row>
                    {all_guides && all_guides.map((item, index) => {

                      let PageURL = item.Category[0].Details_Page_URL

                      let URL = PageURL.Secondary_URL ? PageURL.Secondary_URL : PageURL.URL

                      if ((PageURL.Main_Parent != null) && (PageURL.Sub_Parent == null) && (PageURL.Sub_Parent_Secondary == null)) {
                        URL = PageURL.Main_Parent.URL + '/' + URL
                      }

                      else if ((PageURL.Main_Parent != null) && (PageURL.Sub_Parent != null) && (PageURL.Sub_Parent_Secondary == null)) {
                        URL = PageURL.Main_Parent.URL + '/' + PageURL.Sub_Parent.URL + '/' + URL
                      }

                      else if ((PageURL.Main_Parent != null) && (PageURL.Sub_Parent != null) && (PageURL.Sub_Parent_Secondary != null)) {
                        URL = PageURL.Main_Parent.URL + '/' + PageURL.Sub_Parent.URL + '/' + PageURL.Sub_Parent_Secondary.URL + '/' + URL
                      }
                      return (
                        <Col lg={3}>
                          <Link to={`/${URL}/${item.URL}`} className="guide-link">
                          <div className="other-areas-item img-zoom">
                            <div className="other-areas-img">
                              {item.Tile_Image ?
                                <GatsbyImage image={getImage(item.Tile_Image.url_sharp)} alt={item.Tile_Image.alternativeText} />
                                // <Img fluid={item.Tile_Image.url_sharp.childImageSharp.fluid} alt={item.Tile_Image.alternativeText} /> 
                                : <img src={NoImage} alt={item.Title} />
                              }
                            </div>
                            <Link to={`/${URL}/${item.URL}`} className="btn btn-white">{item.Title} <i className="icon-arrow"></i></Link>
                          </div>
                          </Link>
                        </Col>
                      )
                    }
                    )}
                  </Row>


                </div>
              </Col>
            </Row>
            </motion.div>
          </Container>
        </motion.div>
      )}
    </InView>
  )
}

export default GuidesLanding;