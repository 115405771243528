import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import Img from 'gatsby-image';
import parse from "html-react-parser";
import PlayVideo from '../Play/PlayVideo';
import AdvisorImg from "../../images/area-guide/advisors.jpg"; 
import { FIND_A_PROPERTY_PAGE_URL, VALUATION_PAGE_URL, BRANCHES_PAGE_URL } from "../common/site/constants"
import GenerateLink from "../common/site/generate-link"
import Enquiry from "../Branches/Enquiry";
import InvestorQuestion from "../Branches/InvestorQuestion";

import "../AreaGuideDetails/VideoBlock/VideoBlock.scss"

// markup
const VideoBlock = (props ) => {
  const [isPlay, setPlay] = useState(false);

  return ( 
        <React.Fragment>
           <div className={`video-block-wrap components--AreaGuideDetails--VideoBlock--VideoBlock ${props.count == 0 && props.layout == "Default" ? 'landing-static-content-block' : ''}`}>
           <Container>
                    <Row>
                      <Col xl="8" lg="7">
                        <div className="block-content">
                          <div className="block-text">
                          {parse(props.Add_Static_Content)}
                          
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" lg="5" className="block-content block-text">
                        <div className="guide-details-right-block main-banner">
                          <div className="first">
                          {props.Show_Search_In_Sidebar &&
                          <form>
                            <div class="form-group">
                              <i class="icon-pin"></i>
                              <input placeholder="Enter your postcode or area" type="text" class="address form-control" />
                            </div>
                            <div className="btn-wrap row">
                              <Col lg={6} className="cta col-1">
                                <Link to={VALUATION_PAGE_URL.alias} className="btn btn-secondary">
                                  <span>Selling or Letting?</span>Book a Valuation
                                </Link>
                              </Col>
                              <Col lg={6} className="cta col-2">
                                <Link to={FIND_A_PROPERTY_PAGE_URL.alias} className="btn">
                                  <span>Buying or Renting?</span>Find a Property
                                </Link>
                              </Col>
                            </div>
                          </form>
                          }
                          {props.Sidebar_Custom_CTA_Label && props.Sidebar_Custom_CTA_Link &&
                          <React.Fragment>
                          {props.Sidebar_Custom_CTA_Link.URL === "complaint-popup-form" ?
                          <Enquiry title="Customer Complaint" branch={'Contact form'}  class="btn btn-secondary single-cta" > {props.Sidebar_Custom_CTA_Label} </Enquiry> :
                          props.Sidebar_Custom_CTA_Link.URL === "investor-question-popup-form" ?
                          <InvestorQuestion title="Ask Antony a question about property investment" branch={'Contact form'}  class="btn btn-secondary single-cta" > {props.Sidebar_Custom_CTA_Label} </InvestorQuestion> :
                          <GenerateLink link={props.Sidebar_Custom_CTA_Link} class="btn btn-secondary single-cta">
                            {props.Sidebar_Custom_CTA_Label}
                          </GenerateLink>
                          }
                          </React.Fragment>
                          }
                          {props.Static_Page_Sidebar_Contact_Person &&
                          <div className="advisor-info">
                            <div className="advisor-img">
                              <img src={props.Static_Page_Sidebar_Contact_Person.Image.url} alt={props.Static_Page_Sidebar_Contact_Person.Image.alternativeText}/>
                            </div>
                            <div className="advisor-contact">
                              <span className="advisor-name">{props.Static_Page_Sidebar_Contact_Person.Name}</span>
                              <span className="advisor-designation">{props.Static_Page_Sidebar_Contact_Person.Designation}</span>
                              {props.Static_Page_Sidebar_Contact_Person.Phone &&
                                <a href={`tel:${props.Static_Page_Sidebar_Contact_Person.Phone}`} className="phone-number">
                                  <i className="icon-phone"></i>
                                  <span>{props.Static_Page_Sidebar_Contact_Person.Phone}</span>
                                </a>
                              }
                            </div>
                          </div>
                          }
                          </div>

                          <div className="info-text">
                            <p><b>Not local to Ashford?</b> We’ve got 40+ branches across Kent and surrounding areas – <Link to={BRANCHES_PAGE_URL.alias}>find your local one.</Link></p>
                          </div>


                        </div>
                      </Col>
                    </Row>
             </Container>
           </div>
        </React.Fragment>
    )
}
export default VideoBlock