import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import ModalTiny from "react-bootstrap/Modal"
import _ from "lodash"
import Select, { components } from 'react-select';
import { useWindowSize } from '../../hooks/winodw-size'

import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "gatsby";
import "../AreaGuideDetails/News/News.scss"
import MainNews from "../AreaGuideDetails/News/News"
import { NewsDate } from "../common/site/functions"
import PlayVideo from '../Play/CustomVideo';
import FormBlock from '../Home/FormBlock/FormBlock';
import NoImage from "../../images/no-image.jpg"

// import "./Branches.scss";


const postsPerPage = 16;
let arrayForHoldingPosts = [];


const NewsLanding = () => {

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      allNews(publicationState: LIVE, sort: "Date:desc") {
        id
        Title
        URL
        Video_URL
        Content
        Tile_Image {
          url
          alternativeText
        }
        Short_Description
        Date
        Category {
          Name
          URL
        }
      }

      newsCategories(sort: "Sort:asc") {
        Name
        Sort
        URL
        all_news {
          Title
          URL
        }
      }

      globalModule {
        News_Landing_Page {
          CTA_1_Label
          CTA_2_Label
          Content
          Name
          Show_Chart
          Select_Form {
            Select
          }
          CTA_1_Link {
            id
          }
          CTA_2_Link {
            id
          }
        }
      }

    }
  }
`);

  const allnews = data.glstrapi.allNews;
  const allcats = data.glstrapi.newsCategories;
  const global = data.glstrapi.globalModule.News_Landing_Page;
  const [category, setCategory] = React.useState("all")
  const [filteredTeam, setFilteredTeam] = useState([]);
  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(16);
  const [filterupdate, setFilterUpdate] = React.useState(false)
  const [search_text, setSearchText] = useState('');
  const [loadtext, setLoadText] = useState('');
  const [catupdate, setCatUpdate] = React.useState(1)

  const [windowWidth] = useWindowSize();
  const [isPlay, setPlay] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedArea, setSelectedArea] = useState([{ value: 'all', label: 'All News' }]);
  const [catList, setCatList] = useState([]);
  const showFilter = () => {
    setShow(!show);
  };


  const BranchFilter = (url) => {
    setCatUpdate(2);
    setCategory(url)
    setFilterUpdate(true)
    setNext(16)
    setSearchText("");
  }

  const handlechange = (event) => {
    // remove initial empty spaces
    setCatUpdate(2)
    // var search_val = event.target.value.trimStart();
    var search_val = document.getElementById('search').value
    setSearchText(search_val);
    setFilterUpdate(true)
  }

  const handlechangeOne = (event) => {
    // remove initial empty spaces
    if (event.key == "Enter") {
      setCatUpdate(2)
      // var search_val = event.target.value.trimStart();
      var search_val = document.getElementById('search').value
      setSearchText(search_val);
      setFilterUpdate(true)
    }
  }

  const filteredList = () => {
    setFilteredTeam([])
    var filteredTeamList = []
    if (catupdate == 2) {
      setCatUpdate(3)
    }
    if (category == "all" && search_text == '') {
      filteredTeamList = allnews
    }
    else {
      if (search_text) {
        for (var i = 0; i < allnews.length; i++) {
          var full_name = allnews[i].Title.toLowerCase()
          var full_content = allnews[i].Content.toLowerCase()
          full_content = full_content.replace(/[^\w\s]/gi, '')
          if (full_name.includes(search_text.toLowerCase()) || full_content.includes(search_text.replace(/[^\w\s]/gi, '').toLowerCase())) {
            filteredTeamList.push(allnews[i])
          }
        }
      }
      else {
        filteredTeamList = allnews.filter(branch => {
          let main_category = branch.Category.filter(cat => _.includes(cat, category));
          return main_category.length > 0
        });
      }
    }
    if (filteredTeamList.length == 0) {
      setLoadText("Sorry, No results found")
    }
    setFilteredTeam(filteredTeamList)
    setFilterUpdate(false)
  }

  const loopWithSlice = (start, end) => {
    const slicedPosts = filteredTeam.slice(start, end);
    arrayForHoldingPosts = [...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const loopWithSliceMore = (start, end) => {
    const slicedPosts = filteredTeam.slice(start, end);

    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSliceMore(next, next + 15);
    setNext(next + 15);
  };

  useEffect(() => {
    if ((catupdate == 1 || catupdate == 2) && (filteredTeam.length == 0 || filterupdate)) {
      filteredList()
    }
    loopWithSlice(0, postsPerPage);

    if (_.isEmpty(catList)) {
      let cats = [{ value: 'all', label: 'All News' }];
      allcats.map((area) => {
        if (area.all_news.length > 0) {
          cats.push({ value: area.URL, label: area.Name })
        }

      })
      setCatList(cats)
    }

  }, [filteredTeam, category, search_text]);
  const customStyles = {
    control: styles => ({
      ...styles, outline: 0, border: 'none', boxShadow: 'none', borderRadius: '8px', backgroundColor: '#CCE7EE',
      color: 'red', fontWeight: 'bold', height: "48px", width: "100%", paddingLeft: "10px", paddingRight: "10px"
    }),
    singleValue: base => ({
      ...base,
      color: '#005670'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#005670", // Custom colour
      fontSize: "24px"
    })

  }
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? '-' : '+'}
      </components.DropdownIndicator>
    );
  };
  return (
    <React.Fragment>
      <section className="similar-property news-main-landing-page">
        <Container>
          <Row>

            <div className="news-wrap">
              <Container>
                <Row>
                  <Col md="12">
                    <div className="tab-wrap">
                      <div className="d-none d-lg-flex">
                        {/* <button type="button" className={`all-btn ${category === 'all' && "active"}`} onClick={() => { BranchFilter('all'); showFilter(); }}>All News <span>{show ? "-" : "+"}</span></button> */}
                        <div className={show ? "tabing-list d-block" : "tabing-list "} >
                          <ul className="area-guide-listing-desktop">
                            <li>
                              <a href="javascript:void(0);" className={`all-btn ${category === 'all' && "active"}`} onClick={() => { BranchFilter('all'); showFilter(); }}>All News</a>
                            </li>
                            {catList.map((area, index) => (
                              <React.Fragment>
                                {area.value !== "all" &&
                                  <li>
                                    <a href="javascript:void(0);" className={`${category === area.value && "active"}`} onClick={() => { BranchFilter(area.value); }}>{area.label}</a>
                                  </li>
                                }

                              </React.Fragment>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="d-block d-lg-none">
                        <Select
                          name={`area`}
                          value={selectedArea}
                          options={catList}
                          className={``}
                          onChange={(selected) => {
                            setSelectedArea(selected)
                            BranchFilter(selected.value);
                          }}
                          components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                          classNamePrefix="select"
                          styles={customStyles}
                        />
                      </div>
                      <div className="form news-filter">
                        {/* <i className="icon-search"></i> */}
                        {windowWidth < 768 && <Form.Control autoFocus id="search" onKeyDown={handlechangeOne} placeholder="Search..." />}
                        {windowWidth >= 768 && <Form.Control autoFocus id="search" onKeyDown={handlechangeOne} placeholder="Search news" />}
                        <button type="submit" className="search-btn" onClick={handlechange}> <i className="icon-search"></i></button>
                      </div>

                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            {postsToShow.length == 0 &&
              <p className="no-results-found">{loadtext}</p>
            }

            {postsToShow.slice(0, 1).map((item, index) => (
              <MainNews {...item} />
            ))}

            {postsToShow.length > 1 &&
              <Row className="news-list">
                {postsToShow.slice(1, 4).map((node, index) => (
                  <React.Fragment>
                    <Col lg={4}>
                      <div className="news-item">
                        <div className="image img-zoom">
                          <Link to={node.URL}>
                            <img src={node.Tile_Image ? node.Tile_Image.url : NoImage} alt={node.Tile_Image ? node.Tile_Image.alternativeText : node.Title} />
                          </Link>
                          {node.Video_URL &&
                            <PlayVideo url={node.Video_URL} />
                          }
                        </div>
                        <Link to={node.URL}>
                          <div className="content">
                            <p className="title">{node.Title}</p>
                            <p className="date">{NewsDate(node.Date)}</p>
                          </div>
                        </Link>
                      </div>
                    </Col>
                    {/* {index == 2 &&
                      <Col lg={12}>
                        <FormBlock {...global} />
                      </Col>
                    } */}
                  </React.Fragment>
                ))}
              </Row>
            }
          </Row>
        </Container>

        {/* <Row className="news-list">   */}
        {/* <Col lg={12}> */}
        {postsToShow.length > 5 &&
          <FormBlock {...global} />
        }
        {/* </Col>  */}
        {/* </Row> */}

        <Container>
          <Row>
            {postsToShow.length > 2 &&
              <Row className="news-list">
                {postsToShow.slice(4, 1000).map((node, index) => (
                  <React.Fragment>
                    <Col lg={4}>
                      <div className="news-item">
                        <div className="image img-zoom">
                          <Link to={node.URL}>
                            <img src={node.Tile_Image ? node.Tile_Image.url : NoImage} alt={node.Tile_Image ? node.Tile_Image.alternativeText : node.Title} />
                          </Link>
                          {node.Video_URL &&
                            <PlayVideo url={node.Video_URL} />
                          }
                        </div>
                        <Link to={node.URL}>
                          <div className="content">
                            <p className="title">{node.Title}</p>
                            <p className="date">{NewsDate(node.Date)}</p>
                          </div>
                        </Link>
                      </div>
                    </Col>
                  </React.Fragment>
                ))}
              </Row>
            }

            {arrayForHoldingPosts.length !== filteredTeam.length ?
              <div className="area-guide-load-more people-landing-load-more"><button className="btn btn-pagination" onClick={() => handleShowMorePosts()}>Load more</button></div>
              : ''
            }
          </Row>
        </Container>
      </section>

    </React.Fragment >
  )
}
export default NewsLanding
