import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import _ from "lodash"
import parse from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';


import SEO from "../seo"
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Error from "../../pages/404"
import Banner from '../Home/Banner/BranchBanner';
import BranchDetails from "../PropertyDetails/BranchDetails/BranchDetails"
import About from "../Home/About/BranchAbout"
import Help from "../Home/BookValuation/BranchBookValuation"
import HelpFooter from "../Home/BookValuation/BranchBookValuationFooter"
import GetKnow from "../Home/GetKnow/BranchGetKnow"
import CountBlock from '../Home/CountBlock/OfficeDetailsCountBlock';
import CountBlockLeftContent from '../Home/CountBlock/CountBlockLeftContent';
import NewsInsights from '../Home/NewsInsights/BranchNewsInsights';
import Properties from "../PropertyDetails/Property/Property"
import SuccessStory from "../SuccessStories/SuccessStories"
import AreaGuideModule from "../AreaGuides/BranchDetailsAreaGuide"
import BranchDetailStructuredData from "../Seo/BranchDetailStructuredData"

import logoBlack from "../../images/logo-dark.svg";

const SINGLE_BRANCH = gql`
query GETMAINBRANCH($URL: String!){
    branches(where:{slug: $URL,publish: true}){
        branch_name
        Banner_Image {
        url
        }
        address
        area_tag
        branch_id
        brand_id
        description
        image
        imagetransforms
        lettings_dept
        sales_dept
        slug
        _id
        extra
        success_stories
        viewings_arranged
        valuations
        gross_value_sold
        mortgages_arranged_value
        total_applicants
        cash_buyers
        nothing_to_sell
        first_time_buyers
        under_offer
        own_to_sell
        over_60s
    }
}
`;


function NewsDetails(props) {

    const { loading, error, data } = useQuery(SINGLE_BRANCH, {
        variables: { URL: props.slug }, context: { clientName: "feed" }
    });

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''

    if (loading) return (
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" class="new-loader">
                    <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
                </div>
                <div class="loader-section section-left"></div>
                <div class="loader-section section-right"></div>
            </div>
        </section>
    )

    return (
        <React.Fragment>
            {data.branches.length !== 0 ?
                <div className="homepage home-header branch-details-template">
                    <Header />
                    {data.branches?.map((item, index) => {
                        return (
                            <>
                                <SEO title={item.branch_name} description={`Your local branch of Wards estate agents in ${item.branch_name.replace(" Lettings Agents","").replace("Fine & Country ","")}, ${item.area_tag}`} image={item.Banner_Image ? item.Banner_Image.url : item.image ? item.image[0].url : ''}/>
                                <BranchDetailStructuredData branch={item} />
                                {item.image &&
                                    <Banner {...item} type="branch" />
                                }
                                <BranchDetails {...item} />
                                <About Intro_Title={`Moving you forwards`}/>
                                {item.success_stories?.success_flyer?.length > 0 &&
                                    <SuccessStory {...item} />
                                }

                                <Help />

                                <NewsInsights Latest_News_Heading={`News for ${item.branch_name.replace(" Lettings Agents","").replace("Fine & Country ","")}`} />

                                {item.description &&
                                    <About Intro_Title={`About ${item.branch_name.replace(" Lettings Agents","").replace("Fine & Country ","")} ${item.slug != "medway-lettings" ? "Estate Agents" : ""}`} Intro_Heading='' Intro_Content={item.description} Intro_CTA_Label='' Intro_CTA_Link='' />
                                }

                                <GetKnow />
                                <CountBlock {...item} />
                                <AreaGuideModule name={item.branch_name.replace(" Lettings Agents","").replace("Fine & Country ","")} />
                                <Properties Name={item.branch_name.replace(" Lettings Agents","").replace("Fine & Country ","")} />
                                <HelpFooter />
                                <Footer search="area_office" url={item.slug} area={item.branch_name} />
                            </>
                        )
                    })}
                </div>
                :
                <Error />
            }
        </React.Fragment>
    )
}

export default NewsDetails;
