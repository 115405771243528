import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import parse from 'html-react-parser';
import CountUp from 'react-countup';
import _ from "lodash"

import "./CountBlock.scss";

import { inViewOptions, containerVariants, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const COUNT_UP_START = 0;
const COUNT_UP_DURATION = 3;

const CountBlock = (props) => {
  var currentdate = new Date();
  var datetime = currentdate.getDate() + "/"
    + (currentdate.getMonth() + 1) + "/"
    + currentdate.getFullYear() + " @ "
    + currentdate.getHours() + ":"
    + currentdate.getMinutes() + ":"
    + currentdate.getSeconds();
  return (
    <InView  {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="count-section components--Home--CountBlock--CountBlock"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}

        >
          <Container>
            <Row>
              <Col md={12} lg="11" className="ms-auto">
                <motion.div variants={contentItem} className="block-heading">
                  {props.Statistics_Title &&
                    <h2>{parse(props.Statistics_Title)}</h2>
                  }
                </motion.div>
              </Col>
              <Col md={12} lg="11" className="ms-auto">
                <div className="count-row count-row-first">
                  {props.Add_Stats && props.Add_Stats.slice(0, 2).map((stats, i) => {
                    var countnum = stats.Count.replace(/[^\w\s]/gi, '')
                    var prefix = ''
                    if (stats.Count.indexOf('£') !== -1) {
                      prefix = '£'
                    }
                    return (
                      <motion.div variants={contentItem} className="count-block">
                        {stats.Arrow == "Up" ? <i className="icon-arrow-up"></i> : stats.Arrow == "Down" ? <i className="icon-arrow-down"></i> : ''}

                        <div className={stats.Arrow == "Up" ? 'box' : stats.Arrow == "Down" ? 'box' : ''}>
                          <strong>
                            <CountUp
                              start={inView ? COUNT_UP_START : countnum}
                              end={countnum}
                              delay={0}
                              duration={COUNT_UP_DURATION}
                              prefix={prefix ? "£" : ''}
                              separator=","
                            />
                          </strong>
                          {stats.Details && <span>{stats.Details}</span>}
                        </div>
                      </motion.div>
                    )
                  })}
                </div>
                {props.Add_Stats?.length > 3 &&
                  <div className="count-row count-row-last">
                    {props.Add_Stats && props.Add_Stats.slice(2, 10).map((stats, i) => {
                      var countnum = stats.Count.replace(/[^\w\s]/gi, '')
                      var prefix = ''
                      if (stats.Count.indexOf('£') !== -1) {
                        prefix = '£'
                      }
                      return (
                        <motion.div key={stats + 2} custom={stats + 3} variants={contentItem} className="count-block">
                          {stats.Arrow == "Up" ? <i className="icon-arrow-up"></i> : stats.Arrow == "Down" ? <i className="icon-arrow-down"></i> : ''}
                          <div className={stats.Arrow == "Up" ? 'box' : stats.Arrow == "Down" ? 'box' : ''}>
                            <strong>
                              <CountUp
                                start={inView ? COUNT_UP_START : countnum}
                                end={countnum}
                                delay={0}
                                duration={COUNT_UP_DURATION}
                                prefix={prefix ? "£" : ''}
                                separator=","
                              />
                            </strong>
                            {stats.Details && <span>{stats.Details}</span>}
                          </div>
                        </motion.div>
                      )
                    })}
                  </div>
                }
                <p><b>Source:</b> Wards, part of Arun Estates Ltd, correct as of {datetime}</p>
                {props.About_Statistics ?
                  <>{parse(props.About_Statistics)}</> : ''}
              </Col>
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  );
};

export default CountBlock;
