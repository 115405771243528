import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import _ from "lodash";
import GenerateLink from "../../common/site/generate-link"
import "./OurNetwork.scss"
const OurNetwork = (props) => {

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalModule {
            Our_Network_CTA_Label
            Our_Network_Content
            Our_Network_Title
            Our_Network_CTA_Link {
              id
            }
            Our_Network_Image {
              alternativeText
              url
            }
        }  
      }
    }
  `);
  var btnShow = true;
  if (!_.isEmpty(props.data?.glstrapi?.article?.Choose_Menu)) {
    if (props.data?.glstrapi?.article?.Choose_Menu[0].URL == "estate-agent-network") {
      btnShow = false;
    }
  }

  const network = data.glstrapi.globalModule;

  return (
    <React.Fragment>
      <section className="our-network">
        <Container>
          <Row>
            <Col md="12" lg="6" xl="5">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <div className="about-content">
                  <span className="sub-title">{network.Our_Network_Title}</span>
                  {parse(network.Our_Network_Content)}
                  {network.Our_Network_CTA_Label && btnShow &&
                    <GenerateLink link={network.Our_Network_CTA_Link} class="btn">{network.Our_Network_CTA_Label}<i class="icon-arrow"></i></GenerateLink>
                  }
                </div>
              </ScrollAnimation>
            </Col>
            <Col md="12" lg="6" xl="7">
              <ScrollAnimation animateIn="fadeInRight" animateOnce>
                {network.Our_Network_Image &&
                  <picture className="about-block-img">
                    <img src={network.Our_Network_Image.url} alt={network.Our_Network_Image.alternativeText} />
                  </picture>
                }
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>

      </section>
    </React.Fragment>
  )
}
export default OurNetwork
