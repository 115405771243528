import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from 'html-react-parser';
import $ from 'jquery'
import GenerateLink from "../../common/site/generate-link"

import "./GetKnow.scss";

import { inViewOptions2, containerVariants, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const GetKnow = (props) => {

  return (
    <InView  {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="other-areas-block components--Home-GetKnow--GetKnow"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}

        >
          <section className={`scroll-text-bg ${props?.Services_Background_Title && props?.Services_Background_Title?.replace(/ /g, "-").replace(/&/g, "").replace(/--/g, "-").toLowerCase()}`}>
            <Container>
              <Row>
                <Col lg={12}>
                  {/* {props.Services_Background_Title &&
                    <motion.div variants={fadeInFromTop} className={`block-heading`}>

                      <h2>{props.Services_Background_Title}</h2>

                    </motion.div>
                  } */}
                  <div className="other-areas-list">
                    <motion.div variants={fadeInFromTop}  >
                      <Row>
                        {props.Add_Service_Tile && props.Add_Service_Tile.map((item, index) => (
                          <>
                            {item.Title && item.Link &&
                              <Col lg={3} md={6}>
                                <GenerateLink link={item.Link} class="get-know-link">
                                  <div key={index} className="other-areas-item img-zoom">
                                    <div className="other-areas-img">
                                      {item.Tile_Background_Image &&
                                        <div className="gatsby-image-wrapper">
                                          <GatsbyImage image={getImage(item.Tile_Background_Image.url_sharp)} alt={item.Tile_Background_Image.alternativeText} />
                                        </div>

                                        // <Img fluid={item.Tile_Background_Image.url_sharp.childImageSharp.fluid} alt={item.Tile_Background_Image.alternativeText} />
                                      }

                                    </div>
                                    <div class="btn btn-white">{item.Title} <i className="icon-arrow"></i></div>

                                  </div>
                                </GenerateLink>
                              </Col>
                            }</>

                        ))}
                      </Row>
                    </motion.div>
                  </div>
                  {props.Services_Content &&
                    <div className="bottom-content">
                      {parse(props.Services_Content)}
                    </div>
                  }
                </Col>
              </Row>
            </Container>
          </section>
        </motion.div>
      )}
    </InView>

  );
};

export default GetKnow;
