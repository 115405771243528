import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Card } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import parse from 'html-react-parser';

import { NewsDate } from "../common/site/functions"
import { CASE_STUDIES_PAGE_URL } from "../common/site/constants"


function LatestCaseStudies(props) {

    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <React.Fragment>
            <section className="guides-section-list more-similar-news latest-case-study-list">
                <Container>
                    <Row className="head">
                        {props.Case_Studies_Heading &&
                        <Col xs={6} lg={12}>
                            <span class="sub-title">{props.Case_Studies_Heading}</span>
                        </Col>
                        }
                        <Col xs={6} className="mobile-link">
                            <Link to={CASE_STUDIES_PAGE_URL.alias} class="link-text desktop">More Case Studies<i class="icon-arrow"></i></Link>
                        </Col>
                        
                        <Col lg={8}>
                            <h2>{props.Case_Studies_Title}</h2>
                        </Col>
                        <Col lg={4}>
                            <Link to={CASE_STUDIES_PAGE_URL.alias} class="link-text desktop">More Case Studies<i class="icon-arrow"></i></Link>
                        </Col>
                    </Row>
                    <Row>
                        <Slider {...settings} className="guide-tile-slide">
                            {props.Select_Case_Studies_to_Show && props.Select_Case_Studies_to_Show.map((item, i) => {
                                return (
                                    <Link to={`${CASE_STUDIES_PAGE_URL.alias}/${item.URL}`}>
                                    <div className="guide">
                                        <img src={item.Tile_Image.url} alt={item.Tile_Image.alternativeText} />
                                        <p className="title">{item.Title}</p>
                                        <p className="date">{item.Address}</p>
                                    </div>
                                    </Link>
                                )
                            })}
                        </Slider>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default LatestCaseStudies;
