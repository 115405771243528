import { Link } from "gatsby"
import * as React from "react"
import parse from "html-react-parser";
import { Container, Row, Col } from 'react-bootstrap';
import GenerateLink from "../common/site/generate-link"
import Enquiry from "../Branches/Enquiry";
import InvestorQuestion from "../Branches/InvestorQuestion";
const StaticContent = (props) => {


    return (
        <div className={`video-block-wrap plain-static-content ${props.style == 'Full_Width' ? 'full-content' : ''} ${props.count === 0 && 'top-header'} components--StaticContent--StaticContent pb-5`}>
            <Container>
                <Row>
                    <Col lg={12} className="block-content block-text">
                        <div className="content slide-above">
                            {parse(props.Add_Static_Content.replace("[branch-contact-form]", ''))}
                            {props.Add_Static_Content.includes("[branch-contact-form]") &&
                                <p>We’ve always got an expert local to you with over 100 branches across Kent and the Southeast. For general enquiries, contact us on <a href="tel:0800854499" class="phone-link">0800 854 499</a> or fill out our <Enquiry name={`enquiry`} branch={'Contact form'} class="mail"> contact form </Enquiry></p>
                            }
                            {props.Show_Search_In_Sidebar == false && props.Sidebar_Custom_CTA_Label && props.Sidebar_Custom_CTA_Link &&
                                <React.Fragment>
                                    {props.Sidebar_Custom_CTA_Link.URL === "complaint-popup-form" ?
                                        <Enquiry title="Customer Complaint" branch={'Contact form'} class="btn btn-secondary single-cta" > {props.Sidebar_Custom_CTA_Label} </Enquiry> :
                                        props.Sidebar_Custom_CTA_Link.URL === "investor-question-popup-form" ?
                                            <InvestorQuestion title="Ask Antony a question about property investment" branch={'Contact form'} class="btn btn-secondary single-cta" > {props.Sidebar_Custom_CTA_Label} </InvestorQuestion> :
                                            <GenerateLink link={props.Sidebar_Custom_CTA_Link} class="btn btn-secondary single-cta">
                                                {props.Sidebar_Custom_CTA_Label}
                                            </GenerateLink>
                                    }
                                </React.Fragment>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default StaticContent