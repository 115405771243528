import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";
import News from "./NewsInsights"

const BranchNewsInsights = (props) => {

    const data = useStaticQuery(graphql`
    query {
      glstrapi {
        officeDetailsModule {
            News_Section_Intro
          }
  
      }
    }
    `);
  

    const module = data.glstrapi.officeDetailsModule;


  return (
    <News Latest_News_Intro_Content={module.News_Section_Intro} Latest_News_Heading={props.Latest_News_Heading}/>
  )
}
export default BranchNewsInsights
