import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from 'html-react-parser';
import GenerateLink from "../../common/site/generate-link"
import VirtualTourPlay from "../../Play/CustomVideoFullImageClick"

import ContactModal from "../../ModalForm/Contact"
import "./About.scss"

import { inViewOptions, containerVariants, contentItem, fadeInFromTop, titleVariants, contentVariants } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const About = (props) => {
    const [isPlay, setPlay] = useState(false);
    var isGif = false; 
    var additional_class = ''
    if(props.Intro_Image?.id == "62209a97247aa1cab27cfda5" || props.Intro_Image?.id ==  "62a2ed85083dadc92009d81d"){
        isGif = true; 
    }
    if(props.Intro_Image?.id ==  "62a2ed85083dadc92009d81d"){
        additional_class = 'remote'; 
    }
    return (
        <InView  {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className={`about-section components--Home--About--About ${props.Intro_Image ? 'with-image' : 'without-image'}`}
                    ref={ref}
                    initial="visible"
                    animate={"visible"}
                    variants={containerVariants}

                >
                    <Container>
                        {props.Intro_Image ?
                            <Row className="main-row-align">
                                {props.Intro_Image &&
                                    <Col md="12" lg="6" xl="7">
                                        <motion.div variants={titleVariants} className={`image-video ${props.Intro_Video_URL ? 'img-cursor' : ''} ${additional_class ? additional_class : ''}`}>

                                            {props.Intro_Video_URL ?
                                            <VirtualTourPlay url={props.Intro_Video_URL}>
                                                <GatsbyImage image={getImage(props.Intro_Image.url_sharp)} alt={props.Intro_Image.alternativeText} />
                                                {/* <Img fluid={props.Intro_Image.url_sharp.childImageSharp.fluid} alt={props.Intro_Image.alternativeText} /> */}
                                            </VirtualTourPlay>
                                            :
                                                isGif?
                                                <img src={props.Intro_Image.url} className={additional_class} />
                                                :
                                                <GatsbyImage image={getImage(props.Intro_Image.url_sharp)} alt={props.Intro_Image.alternativeText} />
                                          
                                            }

                                            {/* <Img fluid={props.Intro_Image.url_sharp.childImageSharp.fluid} alt={props.Intro_Image.alternativeText} />
                                            {props.Intro_Video_URL && <VirtualTourPlay url={props.Intro_Video_URL} />} */}

                                        </motion.div>
                                    </Col>
                                }
                                <Col md="12" lg="6" xl="5">
                                    <motion.div variants={contentVariants} className="about-content">
                                        {props.Intro_Title &&
                                            <span className="sub-title">{props.Intro_Title}</span>
                                        }
                                        {props.Intro_Content &&
                                            <div className="main-content">
                                                {parse(props.Intro_Content)}
                                            </div>
                                        }

                                        {props.Intro_CTA_Label && props.Intro_CTA_Link &&
                                            <GenerateLink link={props.Intro_CTA_Link} class="btn">
                                                <span>{props.Intro_CTA_Label}</span>
                                                <i className="icon-arrow"></i>
                                            </GenerateLink>
                                        }
                                        {props.Intro_CTA_Label && props.Intro_CTA_Form === "Contact_Form" &&
                                            <ContactModal class="btn"> {props.Intro_CTA_Label}<i className="icon-arrow"></i></ContactModal>
                                        }

                                    </motion.div>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col md="12">
                                    <motion.div variants={titleVariants} className="about-block-title">
                                        {props.Intro_Title &&
                                            <span className="sub-title">{props.Intro_Title}</span>
                                        }

                                        {props.Intro_Heading &&
                                            <>
                                                {parse(props.Intro_Heading)}
                                            </>
                                        }
                                    </motion.div>
                                </Col>
                                <Col md="12" className="d-lg-flex justify-content-end">
                                    <motion.div variants={contentVariants} className="about-block">
                                        {props.Intro_Content &&
                                            <div className="main-content">
                                                {parse(props.Intro_Content)}
                                            </div>
                                        }

                                        {props.Intro_CTA_Label && props.Intro_CTA_Link &&
                                            <GenerateLink link={props.Intro_CTA_Link} class="btn btn-about-link"><span>{props.Intro_CTA_Label}</span> <i className="icon-arrow"></i></GenerateLink>
                                        }
                                    </motion.div>
                                </Col>
                            </Row>
                        }
                    </Container>
                </motion.section>
            )}
        </InView>

    )
}
export default About
