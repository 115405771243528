import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import VideoImg from "../../../images/area-guide/news_376.jpg"; 
import VideoImg768 from "../../../images/area-guide/news_768.jpg"; 
import VideoImg1400 from "../../../images/area-guide/news_1400.jpg"; 
import VideoImg1600 from "../../../images/area-guide/news_1600.jpg"; 
import { useWindowSize } from '../../../hooks/winodw-size'
import PlayVideo from '../../../components/Play/CustomVideo';
import { NewsDate } from "../../common/site/functions"
import "./News.scss"
// markup
const News = (props ) => {
  const [ windowWidth ] = useWindowSize();
  const [isPlay, setPlay] = useState(false);
  const [show, setShow] = useState(false);
  const showFilter = () => {
    setShow(!show);
  };
  return ( 
        <React.Fragment>
           <div className="news-wrap">
           <Container>
                    <Row>
                      {/* <Col md="12">
                      <div className="block-heading">
                        <h2>News & Opinion</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elin ut varius ipsum ac lacus.</p>
                      </div>
                      </Col> */}
                      {/* <Col md="12">
                        <div className="tab-wrap">
                          <button type="button" className="all-btn" onClick={showFilter}>All News <span>{show ? "-" : "+"}</span></button>
                          <div className={show ? "tabing-list d-block" : "tabing-list "} >
                              <ul>
                                  <li>
                                    <Link href="#">Help & Advice</Link>
                                  </li>
                                  <li>
                                    <Link href="#">Property</Link>
                                  </li>
                                  <li>
                                    <Link href="#">Wards</Link>
                                  </li>
                                  <li>
                                    <Link href="#">Community</Link>
                                  </li>
                                  <li>
                                    <Link href="#">Events</Link>
                                  </li>
                              </ul>
                          </div>
                             <Form className="news-filter">
                               <i className="icon-search"></i>
                                  {windowWidth < 768 && <Form.Control placeholder="Search..." />}
                                  {windowWidth >= 768 && <Form.Control placeholder="Search News" />}
                              
                               <button type="button" className="search-btn"> <i className="icon-search"></i></button>
                               </Form> 
                              
                            </div>
                      </Col> */}
                      </Row>
                      <Row>
                        <div className="row-box flex">
                        <Col lg="7" className="pe-lg-0 ps-lg-0 xxl-8">
                              <picture className="img-zoom about-block-img">
                              <Link to={props.URL}>
                                <img src={props.Tile_Image.url} alt={props.Tile_Image.alternativeText} />
                                </Link>
                                {props.Video_URL &&
                                  <PlayVideo url={props.Video_URL}/>
                                }
                                {/* <button type="button" className="btn-play" onClick={(e) => { setPlay(true) }}>
                                    <i className="icon-play"></i>
                                    </button> */}
                                </picture>
                          </Col>
                          <Col lg="5"  className="ps-lg-0 xxl-4">
                                <div className="news-content">
                                    <Link to={props.URL}>
                                      <h3>{props.Title}</h3>
                                    </Link>
                                    <span className="date-text">{NewsDate(props.Date)}</span>
                                    <p>{props.Short_Description}</p>
                                    <Link to={props.URL} className="btn">
                                       {windowWidth <= 768 && 'Find out more'} 
                                       {windowWidth > 769 && ' Read More'}
                                      <i className="icon-arrow"></i></Link>
                                </div>
                          </Col>
                          </div>
                      </Row>
             </Container>
           </div>
        </React.Fragment>
    )
}
export default News