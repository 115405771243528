import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { Row, Col, Container, Card } from "react-bootstrap";
import parse from 'html-react-parser';

const Accreditation = (props) => {

    return (
      <React.Fragment>
          <section className="accreditation">
              <Container>
              {props.Add_Accreditation && props.Add_Accreditation.map((module, i) => {
                return (
                  <Row className="main">
                        <Col lg={12}>
                            <Row>
                                <Col md={2}>
                                    <div className="image mb-4">
                                        <img src={module.Image.url} alt="" />
                                    </div>
                                </Col>
                                <Col md={10}>
                                    <div className="content">
                                        <h4>{module.Title}</h4>
                                        {parse(module.Content)}
                                        <a href={module.URL} target="_blank" className="link">{module.URL}</a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                  </Row>
                )
              })}
              </Container>
          </section>
      </React.Fragment>
    )
}

export default Accreditation;
