import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import Img01 from "../../../images/Property/staff01.png"
import Img02 from "../../../images/Property/staff02.png"
import Img001 from "../../../images/Property/staff001.png"
import Img002 from "../../../images/Property/staff002.png"
import Tonbridge from "../../../images/branch-awards/BPA Tonbridge.png"
import Birchington from "../../../images/branch-awards/BPA Birchington.png"
import Broadstairs from "../../../images/branch-awards/BPA Broadstairs.png"
import Chatham from "../../../images/branch-awards/BPA Chatham.png"
import Staplehurst from "../../../images/branch-awards/BPA Staplehurst.png"
import Walderslade from "../../../images/branch-awards/BPA Walderslade.png"

import "./BranchDetails.scss"
import { inViewOptions, containerVariants, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const Branch = (props) => {
    return (
        <InView  {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className="branch-details"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}

                >
                    <Container>
                        <Row>
                            <Col className="d-lg-flex">
                                <div className="speak-us">
                                    <motion.h2 variants={contentItem}>Speak to us </motion.h2>
                                    <div className="contact-info d-md-flex justify-content-between">
                                        {props.sales_dept?.manager && props.sales_dept?.manager?.name &&
                                            <motion.div variants={contentItem} className="contact-wrapper">
                                                {
                                                    props.sales_dept?.manager?.image &&
                                                    <div className="contact-img">
                                                        <picture>
                                                            <img src={props.sales_dept?.manager?.image} alt="Img" />
                                                        </picture>
                                                    </div >
                                                }

                                                <div className="contact-details">
                                                    <h2>{props.sales_dept?.manager?.name}</h2>
                                                    <span className="profile">{props.sales_dept?.manager?.job_title}</span>
                                                    {props.sales_dept?.phone &&
                                                        <span className="call-us link-text">
                                                            <i className="icon-call"></i>
                                                            <a className="tel" href={`tel:${props.sales_dept?.phone}`}>
                                                                {props.sales_dept?.phone}
                                                            </a>
                                                        </span>
                                                    }
                                                </div>
                                            </motion.div>
                                        }
                                        {props.lettings_dept?.manager && props.lettings_dept?.manager?.name &&
                                            <motion.div variants={contentItem} className="contact-wrapper">
                                                {
                                                    props.lettings_dept?.manager?.image &&
                                                    <div className="contact-img">
                                                        <picture>
                                                            <img src={props.lettings_dept?.manager?.image} alt="Img" />
                                                        </picture>
                                                    </div >
                                                }

                                                <div className="contact-details">
                                                    <h2>{props.lettings_dept?.manager?.name}</h2>
                                                    <span className="profile">{props.lettings_dept?.manager?.job_title}</span>
                                                    {props.lettings_dept?.phone &&
                                                        <span className="call-us link-text">
                                                            <i className="icon-call"></i>
                                                            <a className="tel" href={`tel:${props.lettings_dept?.phone}`}>
                                                                {props.lettings_dept?.phone}
                                                            </a>
                                                        </span>
                                                    }
                                                </div>
                                            </motion.div>
                                        }
                                    </div>
                                </div>
                                <div className="branch-info">
                                    <motion.div variants={contentItem} className="address order-1">
                                        <h2>Branch Details</h2>
                                        <h3>Address:</h3>
                                        <address>
                                            <div>{props.address.building}</div>
                                            <div>{props.address.street}</div>
                                            <div>{props.address.town}</div>
                                            <div>{props.address.district}</div>
                                            <div>{props.address.county}</div>
                                            <div>{props.address.postcode}</div>
                                        </address>
                                        {props.sales_dept?.email || props.lettings_dept?.email ?
                                            <motion.div variants={contentItem} className="mail order-md-3">
                                                <h2>Email:</h2>
                                                {props.sales_dept.email &&
                                                    <React.Fragment>
                                                        <span className="title">Sales:</span><Link className="mail-us" href={`mailto:${props.sales_dept.email}`}>
                                                            {props.sales_dept.email}</Link>
                                                    </React.Fragment>
                                                }
                                                <br></br>
                                                {props.lettings_dept.email &&
                                                    <React.Fragment>
                                                        <span className="title">Lettings:</span><a className="mail-us" href={`mailto:${props.lettings_dept.email}`}>
                                                            {props.lettings_dept.email}</a>
                                                    </React.Fragment>
                                                }
                                            </motion.div> : ''}
                                    </motion.div>

                                    <motion.div variants={contentItem} className="time-info order-2">
                                        {props.extra.opening_hours_website &&
                                            <div className="office-hours">
                                                <h2>Office Hours</h2>
                                                {props.extra.opening_hours_website && props.extra.opening_hours_website.map((item, i, arr) => {
                                                    return (
                                                        <ul className={arr.length - 1 === i ? "timing last" : "timing"}>
                                                            <li>{item.week_day}:</li>
                                                            <li>{item.timing}</li>
                                                        </ul>
                                                    )
                                                })}
                                            </div>
                                        }
                                        {props.slug == 'tonbridge' ?
                                            <div className="">
                                                <img src={Tonbridge} alt="" />
                                            </div>
                                            : props.slug == 'chatham' ?
                                                <div className="">
                                                    <img src={Chatham} alt="" />
                                                </div> : props.slug == 'birchington' ?
                                                    <div className="">
                                                        <img src={Birchington} alt="" />
                                                    </div> : props.slug == 'broadstairs' ?
                                                        <div className="">
                                                            <img src={Broadstairs} alt="" />
                                                        </div> : props.slug == 'staplehurst' ?
                                                            <div className="">
                                                                <img src={Staplehurst} alt="" />
                                                            </div> : props.slug == 'walderslade' ?
                                                                <div className="">
                                                                    <img src={Walderslade} alt="" />
                                                                </div> :
                                                                ''}

                                    </motion.div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </motion.section >
            )}
        </InView >
    )
}
export default Branch